import TagsInput from "react-tagsinput";
import { useState } from "react";

export default function Tag({
  tags,
  onChangeHandler,
  maxWidth,
  placeholder,
  regexPattern,
  errorMessage = "Invalid input", // Default error message
}) {
  const minWidth = 200;
  const [inputWidth, setInputWidth] = useState(minWidth);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputChangeHandler = (value) => {
    if (value.length > 20) {
      setInputWidth(minWidth + (value.length - 20) * 6);
    } else {
      setInputWidth(minWidth);
    }
    setInputValue(value);
  };

  const tagChangeHandler = (newTags) => {
    if (newTags.length) {
      const last = newTags.pop();
      if (last && last.trim() !== "") {
        newTags.push(last);
      }
    }

    // Validate against the regex pattern
    if (regexPattern && !regexPattern.test(inputValue.trim())) {
      setError(errorMessage); // Set error if validation fails
      return;
    }

    // If valid, call the handler and clear the input
    onChangeHandler(newTags);
    setInputValue(""); // Optionally reset inputValue after updating tags
    setError(""); // Clear any previous error
  };

  return (
    <div>
      <TagsInput
        value={tags}
        onChange={tagChangeHandler}
        addKeys={[188, 13]}
        addOnBlur
        inputValue={inputValue}
        onChangeInput={inputChangeHandler}
        inputProps={{
          style: {
            width: `${inputWidth}px`,
            maxWidth: maxWidth ?? "750px",
          },
          placeholder: placeholder ?? "",
          className: "react-tagsinput-input",
        }}
      />
      {error && (
        <span
          style={{ color: "red", fontSize: "13px" }}
          className="error-message"
        >
          {error}
        </span>
      )}
    </div>
  );
}
