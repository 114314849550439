import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArcgisMap, ArcgisSearch, ArcgisLegend } from '@arcgis/map-components-react';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import Graphic from '@arcgis/core/Graphic';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Polygon from '@arcgis/core/geometry/Polygon';
import '@arcgis/core/assets/esri/themes/light/main.css';
import './MapgroundNew.scss';
import counties from './counties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import ControlPanel from './ControlPanel';
import { MdInfoOutline } from "react-icons/md";
import esriConfig from '@arcgis/core/config'; 
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import * as projection from '@arcgis/core/geometry/projection';
// import Graphic from "@arcgis/core/Graphic";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";

const MapgroundNew = ({ geojson, height, setMapHeight, colorMapping, regulatoryGeojson}) => {
  const mapRef = useRef(null);
  const [view, setView] = useState(null);
  const zoomLevel = 6
  const labelGraphicRefs = useRef([]); 
  const labelGraphicsLayer = useRef(new GraphicsLayer()); 
  const webMapRef = useRef(null);
  const mapViewRef = useRef(null);
  const [isExpandMap, setIsExpandMap] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const graphicsLayerRef = useRef(null); 

  esriConfig.apiKey = 'AAPTxy8BH1VEsoebNVZXo8HurGWhPfAaez9eJYrm8qIMVi0b13mnPntk8juaq2cY9_YOWR0D2v4iwFwnEdBZYLW4wfqlUqv_mtTd6kV1fwdqsCpfCA7_MTBzrojgI1CoioRCBHMkNjMxdgGRsTl5Dos_NMyVrLyxwveYGg0hZLQ6vAPxyFBJCfnF40HQKUzJNED-mNmfSF3Tv92J_sq7nE7sA6HqrC-_aIRxxsHWCCb7Iwhx4ndxFTGvADHSOjGEcmtdAT1_FbpkOzPV';

  const updateLabelPositions = useCallback(() => {
    if (!view || !view.extent) return;
  
    const visibleCounties = [];
  
    // Ensure view extent is projected to WGS84 (same as the county polygons if necessary)
    const projectedExtent = projection.project(view.extent, new SpatialReference({ wkid: 4326 }));
  
    labelGraphicRefs.current.forEach((item) => {
      const { polygon, labelText, labelGraphic } = item;
  
      // Project county polygon to WGS84 if needed
      const projectedPolygon = projection.project(polygon, new SpatialReference({ wkid: 4326 }));
  
      // Check if the projected polygon intersects with the projected extent
      if (geometryEngine.intersects(projectedPolygon, projectedExtent)) {
        // Get the visible part of the polygon in the current view
        const visiblePart = geometryEngine.intersect(projectedPolygon, projectedExtent);
  
        if (visiblePart) {
          visibleCounties.push({ polygon: visiblePart, labelText, labelGraphic });
        }
      }
    });
  
    // Remove existing labels
    labelGraphicsLayer.current.removeAll();
  
    visibleCounties.forEach((county, index) => {
      const { polygon, labelText } = county;
  
      // Calculate the centroid of the polygon
      let centerPoint = polygon.centroid;
  
      // Ensure the centroid is within the polygon; if not, find the nearest point inside
      if (!geometryEngine.contains(polygon, centerPoint)) {
        // Get the nearest vertex within the polygon as a fallback
        centerPoint = geometryEngine.nearestVertex(polygon, centerPoint).coordinate;
      }
  
      // Add the label at the calculated center point
      const newLabelGraphic = addLabel(centerPoint, labelText);
      visibleCounties[index].labelGraphic = newLabelGraphic;
    });
  }, [view]);

useEffect(() => {
  if (!mapViewRef.current) {
    const webMap = new WebMap({
      portalItem: { id: 'e3e26f6069e34951947adaa9def4c51e' },
    });

    webMapRef.current = webMap;

    const mapView = new MapView({
      container: mapRef.current,
      map: webMap,
      zoom: zoomLevel,
      center: [-100.002, 35.36],
      constraints: {
        // Disable map interactions
        clickable: false,
      }
    });
    
    mapViewRef.current = mapView;

    mapView.when(() => {
      setView(mapView);
      counties.forEach((county) => {
        addPolygonAndLabel(county);
        mapView.on("click", (event) => {
          event.stopPropagation(); // Prevent click events
        });
      });
      updateLabelPositions();
    });

    mapView.watch('zoom', (newZoom) => {
      if (newZoom >= 10) {        
        if (labelGraphicsLayer.current && !webMap.layers.includes(labelGraphicsLayer.current)) {
          webMap.add(labelGraphicsLayer.current);
        }
        updateLabelPositions(); 
      } else {
        if (webMap.layers.includes(labelGraphicsLayer.current)) {
          webMap.remove(labelGraphicsLayer.current);
        }
      }
    });

    return () => {
      if(mapViewRef.current){
        mapViewRef.current.destroy();
      }
      if(webMapRef.current){
        webMapRef.current.destroy();
      }
    };
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  // to calculate the zoom level and map center on the basis of geojson array corner points
  useEffect(() => {
    // Check the conditions before executing the main logic
    if (view && geojson && Array.isArray(geojson) && geojson.length > 0) {
        const coordinates = geojson.map(geo => {
            return geo.geometry.coordinates;
        });
        if (coordinates.length) {
            let minLongitude = coordinates[0][0];
            let maxLongitude = coordinates[0][0];
            let minLatitude = coordinates[0][1];
            let maxLatitude = coordinates[0][1];

            // Iterate over the remaining coordinates to find the min and max values
            for (let i = 1; i < coordinates.length; i++) {
                const [longitude, latitude] = coordinates[i];
                if (longitude < minLongitude) {
                    minLongitude = longitude;
                }
                if (longitude > maxLongitude) {
                    maxLongitude = longitude;
                }
                if (latitude < minLatitude) {
                    minLatitude = latitude;
                }
                if (latitude > maxLatitude) {
                    maxLatitude = latitude;
                }
            }

            // Create the bounds array with the southwest and northeast corners
            const bounds = [
                [minLongitude, minLatitude],   // Southwest corner [longitude, latitude]
                [maxLongitude, maxLatitude],   // Northeast corner [longitude, latitude]
            ];
            const calculateCenterAndZoom = (bounds) => {
                const [sw, ne] = bounds;
                const WORLD_WIDTH = 256;
                const PADDING = 30; // Adjust the padding as needed
                const lngDiff = Math.abs(ne[0] - sw[0]);
                const latDiff = Math.abs(ne[1] - sw[1]);
                const center = [(Number(ne[0]) + Number(sw[0])) / 2, (Number(ne[1]) + Number(sw[1])) / 2];
                const latZoom = Math.log2((WORLD_WIDTH - PADDING) / latDiff);
                const lngZoom = Math.log2((1800 - PADDING) / lngDiff);
                const zoom = Math.min(latZoom, lngZoom);
                return {
                    longitude: center[0],
                    latitude: center[1],
                    zoom: zoom > 11 ? 11 : zoom,
                };
            };

            const { longitude, latitude, zoom } = calculateCenterAndZoom(bounds);
            view.goTo({ 
                zoom,
                center: [longitude, latitude]
            });
        }
    }
    // eslint-disable-next-line
}, [geojson]);

  const addPolygonAndLabel = (county) => {
    const polygon = new Polygon({
      rings: county.rings,
      spatialReference: { wkid: 4326 },
    });
    
    labelGraphicRefs.current.push({
      polygon: polygon,
      labelText: county.name,
      labelGraphic: null,
    });
  };

  const addLabel = (centerPoint, text) => {
    const textSymbol = new TextSymbol({
      text: text,
      color: 'black',
      font: {
        size: '16px',
        family: 'sans-serif',
        weight: 'bold',
      },
      horizontalAlignment: 'center',
      verticalAlignment: 'middle',
    });

    const labelGraphic = new Graphic({
      geometry: centerPoint,
      symbol: textSymbol,
    });

    labelGraphicsLayer.current.add(labelGraphic);
    return labelGraphic;
  };

  useEffect(() => {
    if (view && geojson && Array.isArray(geojson) && geojson.length > 0) {
      const featureCollection = {
        type: 'FeatureCollection',
        features: geojson,
      };
  
      const blob = new Blob([JSON.stringify(featureCollection)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
  
      const sizeVV = {
        type: "size",
        valueExpression: "$view.scale",
        stops: [
          { size: 1.25, value: 4500000 },
          { size: 1.5, value: 2000000 },
          { size: 1.75, value: 1500000 },
          { size: 2, value: 1200000 },
          { size: 2.5, value: 1000000 },
          // { size: 8, value: 750000 },
          { size: 5, value: 500000 },
          { size: 7.5, value: 250000 },
          { size: 12, value: 100000 },
        ]
      };
  
      const newGeojsonLayer = new GeoJSONLayer({
        url: url,
        popupTemplate: {
          title: '{name}',
          content: '{description}',
        },
        fields: [
          { name: 'name', type: 'string' },
          { name: 'description', type: 'string' },
          { name: 'color', type: 'string' },
        ],
        renderer: {
          type: 'unique-value',
          field: 'color',
          uniqueValueInfos: geojson.map(feature => ({
            value: feature.properties.color,
            symbol: {
              type: 'simple-marker',
              color: feature.properties.color,
              size: '3px',
              outline: { width: 0 },
            }
          })),
          visualVariables: [sizeVV]
        },
      });
  
      if (view.map) {
        view.map.add(newGeojsonLayer, 1);
      }
  
      return () => {
        if (view && newGeojsonLayer && view.map && view.map.layers.includes(newGeojsonLayer)) {
          view.map.remove(newGeojsonLayer);
        }
        if (view && graphicsLayerRef.current && view.map && view.map.layers.includes(graphicsLayerRef.current)) {
          view.map.remove(graphicsLayerRef.current);
        }
        URL.revokeObjectURL(url);
      };
    }
    // eslint-disable-next-line
  }, [view, geojson]);
  useEffect(() => {
  if (view) {
    view.watch('extent', () => {
      if (view.zoom >= 10) {
        updateLabelPositions(); // Only run when zoom level is 10 or more
      }
    });
  }
}, [view, updateLabelPositions]); 

useEffect(() => {
  if (view && regulatoryGeojson) {
    addSquarePolygons(regulatoryGeojson, view);
  }
}, [view, regulatoryGeojson]);


const addSquarePolygons = (regulatoryGeojson, mapView) => {
  // If there's an existing graphics layer, remove it
  if (graphicsLayerRef.current) {
    mapView.map.remove(graphicsLayerRef.current);
  }

  // Create a new graphics layer
  const graphicsLayer = new GraphicsLayer();
  graphicsLayerRef.current = graphicsLayer; // Store the reference

  // Add the graphics layer to the map at index 1
  mapView.map.add(graphicsLayer, 1);

  // Define a Set to track unique coordinates
  const uniqueCoordinates = new Set();

  // Extract coordinates from the GeoJSON
  const coordinatesArray = regulatoryGeojson
  .slice(0, 10000)
  .flatMap((feature) => {
    // Split by '|' to handle multiple lglt values
    const lgltValues = feature.lglt ? feature.lglt.split("|") : [];
    // Map each lglt value to coordinates
    return lgltValues.map((lglt) => {
      const [lon, lat] = lglt.split(",").map(Number);
      return [lon, lat];
    });
  })
    .filter(
      (coords) =>
        Array.isArray(coords) &&
        coords.length === 2 &&
        coords.every(Number.isFinite)
    );

  // Define the square size in meters
  const squareSizeInMeters = 1620;

  // Convert meters to degrees
  const metersToDegrees = (meters) => meters / 111000;
  const halfSizeInDegrees = metersToDegrees(squareSizeInMeters / 2);

  // Add squares for each coordinate
  coordinatesArray.forEach((coords) => {
    if (Array.isArray(coords) && coords.length === 2) {
      const [lon, lat] = coords;
      const uniqueKey = `${lon.toFixed(6)},${lat.toFixed(6)}`; // Create a unique key for the coordinate

      if (!uniqueCoordinates.has(uniqueKey)) {
        uniqueCoordinates.add(uniqueKey); // Add the key to the Set

        // Define the corners of the square based on the center point
        const squareCoordinates = [
          [lon - halfSizeInDegrees * 1.22, lat - halfSizeInDegrees], // bottom-left
          [lon + halfSizeInDegrees * 1.2, lat - halfSizeInDegrees], // bottom-right
          [lon + halfSizeInDegrees * 1.2, lat + halfSizeInDegrees], // top-right
          [lon - halfSizeInDegrees * 1.22, lat + halfSizeInDegrees], // top-left
          [lon - halfSizeInDegrees * 1.22, lat - halfSizeInDegrees], // back to bottom-left to close
        ];

        // Create a polygon
        const polygon = new Polygon({
          rings: [squareCoordinates],
          spatialReference: { wkid: 4326 },
        });

        // Create a symbol for rendering the polygon
        const polygonSymbol = new SimpleFillSymbol({
          color: [72, 68, 70, 0.8], // Color of the polygon
          outline: {
            color: [51, 51, 204, 0],
            width: 0,
          },
          opacity: 0.5
        });

        // Create a graphic for the polygon
        const polygonGraphic = new Graphic({
          geometry: polygon,
          symbol: polygonSymbol,
        });

          // Add the polygon to the layer
        graphicsLayer.add(polygonGraphic);
      }
    }
  });
};

  return (
    <ArcgisMap
      zoom="20"
      itemId="e3e26f6069e34951947adaa9def4c51e"
      onArcgisViewReadyChange={(event) => {
      }}
    >
      <ArcgisSearch position="top-right" />
      <ArcgisLegend position="bottom-left" />
      <div
        ref={mapRef}
        style={{ width: '100%', height: height || '53vh' }} 
      />
      { Object.keys(colorMapping).length > 0 && showInfo && <ControlPanel colors={colorMapping}/>}
      <FontAwesomeIcon className="btn expand" icon={isExpandMap ? faCompressArrowsAlt : faExpandArrowsAlt } onClick={() => {
        if(isExpandMap) {
          setMapHeight([53, 47]);
        } else {
          setMapHeight([103, 0]);
        }
        setIsExpandMap(!isExpandMap);
      }}/>
    { Object.keys(colorMapping).length ? (
      <div className='info-icon'>
        <MdInfoOutline
              className={`icon`}
              onClick={() => setShowInfo(!showInfo)}
        />
      </div>) : '' }
    </ArcgisMap>
  );
};


export default MapgroundNew;