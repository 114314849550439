import * as types from "./types";

export const getDailyReport = () => ({
  type: types.FETCH_DAILY_REPORT_REQUEST,
});

export const setReportRowHeightAndMode = (id, tab, height, isExpand) => ({
  type: types.SET_REPORT_ROW_HEIGHT_AND_MODE,
  payload: { id, tab, height, isExpand },
});

export const getDocument = (payload) => ({
  type: types.FETCH_DAILY_REPORT_DOC_REQUEST,
  payload
});

export const getDailyReportWithParams = (params) => ({
  type: types.FETCH_DAILY_REPORT_REQUEST_PARAMS,
  payload: params,
});

export const deleteRecord = (record) => ({
  type: types.DELETE_RECORD_REQUEST,
  payload: {record},
});

export const upsertRecord = (data, tab) => ({
  type: types.UPSERT_RECORD_REQUEST,
  payload: { data, tab },
})