/**
 * User Log Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/dailyReport";
import { toast } from "react-toastify";

export function* getDailyReport() {
  try {
    const data = yield call(api.doFetchDailyReport);
    if (data) {
      yield put({
        type: types.FETCH_DAILY_REPORT_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: types.FETCH_DAILY_REPORT_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_DAILY_REPORT_FAILURE,
      payload: { err },
    });
  }
}

export function* getDailyReportDoc({ payload }) {
  try {
    const data = yield call(api.doFetchDocument, payload);
    if (data) {
      yield put({
        type: types.FETCH_DAILY_REPORT_DOC_SUCCESS,
        // payload: data,
        payload: { pdfData: data }
      });
    } else {
      yield put({
        type: types.FETCH_DAILY_REPORT_DOC_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_DAILY_REPORT_DOC_FAILURE,
      payload: { err },
    });
  }
}

export function* getDailyReportWithParams({ payload }) {
  try {
    const data = yield call(api.doFetchDailyReportWithParams, payload);
    if (data) {
      yield put({
        type: types.FETCH_DAILY_REPORT_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: types.FETCH_DAILY_REPORT_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_DAILY_REPORT_FAILURE,
      payload: { err },
    });
  }
}

export function* deleteRecord({ payload: { record } }) {
  try {
    const resData = yield call(api.doDeleteRecord, record);
    if (resData) {
      yield put({
        type: types.DELETE_RECORD_SUCCESS,
        payload: record,
      });
      toast.success("Record deleted successfully");
    }
  } catch (err) {
    yield put({
      type: types.DELETE_RECORD_FAILURE,
      payload: { err },
    });
    toast.error("Failed to delete record");
  }
}

export function* upsertRecord({ payload }) {
  try {
    const resData = yield call(api.doUpsertRecord, payload);
    if (resData[0]) {
      yield put({
        type: types.UPSERT_RECORD_SUCCESS,
        payload: { data: resData[0], tab: payload.tab, action: `${payload.data.id ? 'edit' : 'add'}` }
      });
      toast.success(`Record ${payload.data.id ? 'updated' : 'inserted'} successfully`);
    }
  } catch (err) {
    yield put({
      type: types.UPSERT_RECORD_FAILURE,
      payload: { err },
    });
    toast.error("Something went wrong");
  }
}


// User Log Saga
export default function* dailyReportSaga() {
  yield all([
    takeEvery(types.FETCH_DAILY_REPORT_REQUEST, getDailyReport),
    takeEvery(types.FETCH_DAILY_REPORT_DOC_REQUEST, getDailyReportDoc),
    takeEvery(types.FETCH_DAILY_REPORT_REQUEST_PARAMS, getDailyReportWithParams),
    takeEvery(types.DELETE_RECORD_REQUEST, deleteRecord),
    takeEvery(types.UPSERT_RECORD_REQUEST, upsertRecord)
  ]);
}
