import { connect } from "react-redux";
import { BsPersonCircle, BsMoonStarsFill, BsSunFill } from "react-icons/bs";
import { logout, changeTheme } from "../../../actions/userAction";
import { useNavigate } from "react-router-dom";
import { resetTrs } from "../../../actions/instrumentAction";

function NavBar({ user, logout, changeTheme, theme, resetTrs, trs }) {
  const navigate = useNavigate();

  const handleThemeChange = mode => {
    if (theme !== mode) {
      localStorage.setItem('theme', mode);
      changeTheme(mode);
    }
  };

  return (
    <div className={`navbar${theme === "dark" ? "-dark" : ""}`}>
      <div className="left">
        <button className="btn btn-info nav-btn-info" onClick={() => navigate("/")}>
          Advanced Search
        </button>
        <button
          className="btn btn-info ml-2 nav-btn-info"
          onClick={() => {
            if (trs) {
              resetTrs();
            }
            navigate("/dash-board");
          }}
        >
          Section Overview
        </button>
      </div>
      <div className="controls">
        <div className="btn btn-icon dropdown d-flex">
          {theme === 'dark' ? <BsMoonStarsFill /> : <BsSunFill />} 
          <div className="dropdown-menu">
            <button onClick={() => handleThemeChange('light')} className="btn btn-link btn-micon d-flex align-items-center">
              <BsSunFill />
              &nbsp;&nbsp;Light
            </button>
            <button onClick={() => handleThemeChange('dark')} className="btn btn-link btn-micon d-flex align-items-center">
              <BsMoonStarsFill />
              &nbsp;&nbsp;Dark
            </button>
          </div>
        </div>
        <div className="btn btn-icon dropdown d-flex">
          <BsPersonCircle />
          <div className="dropdown-menu">
            <div className="profile">
              <div className="name">
                {user.firstname}&nbsp;{user.lastname}
              </div>
              <div className="email">{user.username}</div>
            </div>
            <div className="dropdown-divider"></div>
            <button
              className="btn btn-link btn-micon d-flex align-items-center"
              onClick={() => navigate("/my-profile")}
            >
              My Profile
            </button>
            {user.isAdmin && (
              <>
                <button
                  className="btn btn-link btn-micon d-flex align-items-center"
                  onClick={() => navigate("/user-log")}
                >
                  User Log
                </button>
                <button
                  className="btn btn-link btn-micon d-flex align-items-center"
                  onClick={() => navigate("/error-log")}
                >
                  Error Log
                </button>
                <button
                  className="btn btn-link btn-micon d-flex align-items-center"
                  onClick={() => navigate("/data-log")}
                >
                  Data Log
                </button>
                <button
                  className="btn btn-link btn-micon d-flex align-items-center"
                  onClick={() => navigate("/user-management")}
                >
                  User Management
                </button>
                <button
                  className="btn btn-link btn-micon d-flex align-items-center"
                  onClick={() => navigate("/admin-daily-report")}
                >
                  Daily Report
                </button>
              </>
            )}
            <button
              className="btn btn-link btn-micon d-flex align-items-center"
              onClick={() => navigate("/okcr")}
            >
              OKCR Integration
            </button>
            <button
              className="btn btn-link btn-micon d-flex align-items-center"
              onClick={() => logout(user.username)}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  theme: state.user.theme,
  trs: state.instrument.trs,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (email) => dispatch(logout(email)),
  changeTheme: (mode) => dispatch(changeTheme(mode)),
  resetTrs: () => dispatch(resetTrs()),
});

const NavBarContainer = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default NavBarContainer;
