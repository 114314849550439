import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/modal";
import { udpateMyPassword, updateUserInfo } from "../../actions/userManagementAction";
import NavBarContainer from "../../components/common/navbar";
import "./myProfile.scss";

function MyProfile({ theme, user, udpateMyPassword, updating, updateSuccess, updateUserInfo }) {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user.firstname || '');
  const [lastName, setLastName] = useState(user.lastname || '');
  const [company, setCompany] = useState(user.company || '');
  const [address, setAddress] = useState(user.address || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [error, setError] = useState('');
  const [profileErrors, setProfileErrors] = useState({});

  const closeResetPasswordModal = useCallback(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setShowResetPassword(false);
  }, []);

  useEffect(() => {
    if (!updating && updateSuccess) {
      closeResetPasswordModal();
    }
  }, [updating, updateSuccess, closeResetPasswordModal]);

  const isDataValid = () => {
    const errors = {};
    let isValid = true;
    if (phone && !/^\d{10}$/.test(phone)) {
      errors.phone = 'Phone Number must be 10 digits.';
      isValid = false;
    }
    if(!firstName) {
      errors.firstName = 'First Name is required.';
      isValid = false;
    }
    if(!lastName) {
      errors.lastName = 'Last Name is required.';
      isValid = false;
    }
    setProfileErrors(errors);
    return isValid;
  };

  const handleUpdateProfile = () => {
    if (isDataValid()) {
      const updatedUser = {
        fn: firstName,
        ln: lastName,
        co: company,
        ad: address,
        ph: phone
      };
      updateUserInfo(updatedUser);
    }
  };

  const handleChangePassword = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError('All fields are required.');
      return;
    }
    
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }

    setError('');
    
    udpateMyPassword({
      epwd: currentPassword,
      npwd: newPassword,
    });
  };

  return (
    <div className={`dashboard${theme === "dark" ? "-dark" : ""} d-flex flex-column`}>
      <NavBarContainer redirect={() => navigate("/dash-board")} />
      <div className="content d-flex flex-column"  style={{ backgroundColor: theme === "dark" ? "#1e1e2d" : "#ffffff", borderRadius: "5px"}}>
        <div className="profile-container" style={{ marginLeft: "20px" }}>
          <h2>My Profile</h2>
          <form >
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {profileErrors.firstName && <div className="error">{profileErrors.firstName}</div>}
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {profileErrors.lastName && <div className="error">{profileErrors.lastName}</div>}
            </div>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={user.username}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Company</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                className="form-control bg-transparent myprofile-form"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {profileErrors.phone && <div className="error">{profileErrors.phone}</div>}
            </div>
            <button
              type="button"
              className="btn btn-primary change-password-btn"
              onClick={() => setShowResetPassword(true)}
              style={{ right: "10px"}}
            >
              Change Password
            </button>
          </form>
        </div>
        <div className="update-button-container">
          <button
            className="btn btn-primary"
            onClick={handleUpdateProfile}
            style={{ position: "absolute", right: "30px", bottom: "30px" }}
          >
            Update Profile
          </button>
        </div>
      </div>
      <Modal
        showModal={showResetPassword}
        header="Change Password"
        theme={theme}
        html={
          <div className="mb-3">
            <div className="form-group">
              <label>Current Password</label>
              <input
                placeholder="Enter current password"
                name="currentPassword"
                className="form-control modal-form bg-transparent myprofile-form"
                type="password"
                autoComplete="off"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                placeholder="Enter new password"
                name="newPassword"
                className="form-control modal-form bg-transparent myprofile-form"
                type="password"
                autoComplete="off"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                placeholder="Confirm new password"
                name="confirmPassword"
                className="form-control modal-form bg-transparent myprofile-form"
                type="password"
                autoComplete="off"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="error">{error}</div>}
          </div>
        }
        footerHtml={
          <div>
            <button
              className="btn btn-primary mr-3"
              onClick={handleChangePassword}
            >
              Change
            </button>
            <button
              className="btn btn-info"
              onClick={closeResetPasswordModal}
            >
              Cancel
            </button>
          </div>
        }
        onHide={closeResetPasswordModal}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  theme: state.user.theme,
  trs: state.instrument.trs,
  updating: state.userManagement.updating,
  updateSuccess: state.userManagement.updateSuccess,
  error: state.userManagement.error,
});

const mapDispatchToProps = (dispatch) => ({
  udpateMyPassword: (userDetails) => dispatch(udpateMyPassword(userDetails)),
  updateUserInfo: (userDetails) => dispatch(updateUserInfo(userDetails))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
