import { connect } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "../../components/common/table";
import { dailyReportTabs, headers } from "./tabDetails";
import ExcelJS from "exceljs";
import moment from "moment";
import { useEffect, useState } from "react";
import { getDailyReport, getDocument, getDailyReportWithParams } from "../../actions/dailyReport";
import PdfPopUp from "../../components/common/pdfPopUp";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { MdEditNote } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import DailyReportModal from "./modal";
import NavBarContainer from "../../components/common/navbar";
function DailyReport({
   theme,
   loading,
   reportData,
   getDocument, 
   selectedPdf, 
   formattedDate,
   getDailyReportWithParams,
   user,
   pageContext,
}) {
  const [activeTab, setActiveTab] = useState(dailyReportTabs[0].key);
  const isAdmin = !!user && pageContext === "admin";
  const [selectedRecord, setSelectedRecord] = useState({ id: null, tabKey: null, header: '', action: null });
  const [showPdf, setShowPdf] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const date = moment().subtract(i, 'days');
    return { label: date.format('MM/DD/YYYY'), value: date.format('YYYY-MM-DD')};
  });
  const [selectedDate, setSelectedDate] = useState(last7Days[0]);

  // Dropdown options
  const dropdownOptions = [
    ...last7Days.map(date => ({ label: date.label, value: date.value })),
    { label: "Last 7 Days", value: "ALL" }
  ];
  const loadingDiv = (
    <>
      {loading && <span className="ml-2"> Fetching Data. Please Wait... </span>}
    </>
  );

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord({ id: null, tabKey: null, header: '' });
  }
  const updatedTabs = dailyReportTabs.map((tab) => {
  const columns = tab.cols.map((col) => col.headerName);
  if(!['welltransfers'].includes(tab.key) && !columns.includes('Action') && isAdmin) {
    return {
      ...tab,
      cols: [
        ...tab.cols,
        {
          headerName: "Action",
          resizable: false,
          minWidth: 100,
          maxWidth: 100,
          cellRenderer: ({ data }) => (
            <>
              <MdEditNote
              className="btn-icon"
              onClick={() => {
                setSelectedRecord({ id: data.Id, tabKey: tab.key, header: tab.name, action: "edit" });
                setShowModal(true);
              }}
            />
               <IoMdClose
                className="btn-icon"
                onClick={() => {
                  setSelectedRecord({ id: data.Id, tabKey: tab.key, header: tab.name, action: "delete" });
                  setShowModal(true);
                }}
              />
            </>
          ),
        },
      ],
    };
  }
  return tab;
});

  useEffect(() => {
    getDailyReportWithParams({ dt: selectedDate.value });
  }, [
    getDailyReportWithParams,
    selectedDate]);

  const handlePrint = () => {
    let tableHTML = `<h2 style="float:right;">${selectedDate.label}</h2>`;
    const printWindow = window.open("", "", "height=800,width=1000");
    printWindow.document.write(
      "<html><head><title>Halodev Daily Report</title>"
    );
    printWindow.document.write("<style>");
    printWindow.document.write("@page { size: landscape; }");
    printWindow.document.write(
      "table { width: 100%; border-collapse: collapse; }"
    );
    printWindow.document.write(
      "th, td { border: 1px solid black; padding: 8px; text-align: left; }"
    );
    printWindow.document.write("th { background-color: #f2f2f2; }");
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body >");
    dailyReportTabs.forEach((tab) => {
      const cols = tab.cols.filter((col) => col.headerName !== "View" && col.headerName !== "Action");      
      const data = reportData[tab.key];
      tableHTML += `
          <h1>${tab.name}</h1>
          <table>
            <thead>
              <tr>
                ${cols.map((col) => `<th>${col.headerName}</th>`).join("")}
              </tr>
            </thead>
            <tbody>
              ${
                data.length
                  ? data
                      .map(
                        (row) => `
                <tr>
                  ${cols
                    .map((col) => {
                      const data =
                        row[col.field] instanceof Date
                          ? row[col.field].toLocaleDateString("en-US", {
                              timeZone: "UTC",
                            })
                          : row[col.field] || "";
                      return `<td>${data}</td>`;
                    })
                    .join("")}
                </tr>
              `
                      )
                      .join("")
                  : `
              <tr><td colspan=${cols.length} style="text-align: center">No rows to show</td></tr>
              `
              }
            </tbody>
          </table>
          <br/>
        `;
    });
    printWindow.document.write(tableHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

 const handleDownload = async () => {
   // Create a workbook
   const workbook = new ExcelJS.Workbook();
   dailyReportTabs.forEach((tab) => {
     // Sanitize the worksheet name to remove invalid characters
     const sanitizedTabName = tab.name.replace(/[/*?:[\]]/g, "_"); // Updated regex

     // Create a worksheet
     const worksheet = workbook.addWorksheet(sanitizedTabName);

     // Add columns to sheet
     worksheet.columns = tab.cols
       .filter(
         (col) => col.headerName !== "View" && col.headerName !== "Action"
       )
       .map((col) => ({
         header: col.headerName,
         key: col.field,
         width: 20, // Adjust width as needed
       }));

     // Add data rows in sheet
     reportData[tab.key].forEach((item) => worksheet.addRow(item));

     // Enable text wrapping
     worksheet.eachRow({ includeEmpty: true }, (row) => {
       row.eachCell((cell) => {
         cell.alignment = { wrapText: true };
       });
     });
   });

   // Write to a file
   const buffer = await workbook.xlsx.writeBuffer();
   const blob = new Blob([buffer], {
     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   });
   const url = URL.createObjectURL(blob);
   const a = document.createElement("a");
   a.href = url;
   a.download = `DailyReport_${moment().format("YYYY-MM-DD")}(${
     selectedDate.label
   }).xlsx`;
   a.click();
   URL.revokeObjectURL(url);
 };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <div
      className={`dashboard${
        theme === "dark" ? "-dark" : ""
      } d-flex flex-column`}
    >
      {isAdmin && <NavBarContainer />}
      <div className={`report-head ${isAdmin ? 'admin-header ' : 'navbar'}${theme === "dark" ? "-dark" : ""}`}>
        Daily Report&nbsp;
        {!loading && formattedDate && (
          <span className="report-date">
            {"(As of " + formattedDate + " CST)"}
          </span>
        )}
        <div
          className="dropdown"
          style={{ position: "absolute", top: isAdmin ? "120px" : "10px", left: "30px" }}
        >
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {selectedDate.label}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {dropdownOptions.map((option, index) => (
              <button
                className="dropdown-item"
                type="button"
                key={index}
                onClick={() => setSelectedDate(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        {!loading && (
          <div
            style={{ alignSelf: "center", position: "absolute", right: "30px", top: isAdmin ? "120px" : "" }}
          >
            <button
              className="btn btn-info"
              onClick={() => {
                handlePrint();
              }}
            >
              Print
            </button>
            <button
              className="btn btn-info ml-2"
              onClick={() => {
                handleDownload();
              }}
            >
              Download
            </button>
          </div>
        )}
      </div>
      <div
        className="content d-flex flex-column"
        style={{ marginTop: "-25px" }}
      >
        <div className="data d-flex flex-column">
          <Tabs
            defaultActiveKey={updatedTabs[0].key}
            onSelect={handleTabSelect}
          >
            {updatedTabs.map((tab) => {
              if (tab.key === "regulatory" || tab.key === "permits") {
                tab.cols = [
                  {
                    headerName: "View",
                    field: "vw",
                    width: 80,
                    cellRenderer: ({ data }) => {
                      // eslint-disable-next-line
                      return data.vw ? (<a
                          className="check-pointer"
                          onClick={() => {
                            setShowPdf(true);
                            getDocument({ tab: tab.key, eid: data.vw });
                          }}
                        >
                          View
                        </a>
                      ) : (
                        ""
                      );
                    },
                    wrapHeaderText: true,
                  },
                  ...tab.cols.filter((col) => col.headerName !== "View"),
                ];
              }
              return (
                <Tab eventKey={tab.key} title={tab.name} key={tab.key}>
                  {loadingDiv}
                  <div className="digital d-flex flex-column daily-report">
                    <Table
                      cols={tab.cols}
                      data={reportData[tab.key]}
                      columnDef={{ wrapText: true, autoHeight: true }}
                      height={42}
                    />
                  </div>
                </Tab>
              );
            })}
          </Tabs>
          {["permits", "spudnotice", "completions", "regulatory"].includes(activeTab) &&
            isAdmin && (
              <button
                style={{right: '130px'}}
                className="btn btn-primary add"
                onClick={() => {
                  setSelectedRecord({tabKey: activeTab, header: headers[activeTab], action: 'add'});
                  setShowModal(true);
                }}
              >
                +
              </button>
            )}
        </div>
      </div>
      <PdfPopUp
        selectedPdf={selectedPdf}
        showModal={showPdf}
        onHide={() => setShowPdf(false)}
      ></PdfPopUp>
      {showModal && (
        <DailyReportModal
          showModal={showModal}
          closeModal={closeModal}
          theme={theme}
          selectedRecord={selectedRecord}
          dailyReportTabs={dailyReportTabs.map((tab) => ({
            ...tab,
            data: reportData[tab.key],
          }))}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.dailyReport.loading,
    reportData: state.dailyReport.reportData,
    theme: state.user.theme,
    selectedPdf: state.dailyReport.pdf,
    formattedDate: state.dailyReport.formattedDate,
    user: state.user.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDailyReport: () => dispatch(getDailyReport()),
  getDocument: (payload) => dispatch(getDocument(payload)),
  getDailyReportWithParams: (payload) => dispatch(getDailyReportWithParams(payload)),
});

const DailyReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyReport);

export default DailyReportContainer;
