import axios from "axios";

const docApis = {
  regulatory: "/document/occregulatorydocument",
  permits: "/document/occpermitdocument",
};

const apiKey = {
  regulatory: "order",
  permits: "permit",
  spudnotice: "spud",
  completions: "completion",
};

export const doFetchDailyReport = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/getdailyreport", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doFetchDocument = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(docApis[payload.tab], payload, {
        baseURL: process.env.REACT_APP_API_URL,
        responseType: "arraybuffer",
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const doFetchDailyReportWithParams = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/search/getdailyreport", 
        params,
        {
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const doDeleteRecord = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/delete${apiKey[payload.tabKey]}`, { data: { id: payload.id } })
      .then((response) => {
        console.log("Delete response:", response.data); // Log the response
        resolve(response.data);
      })
      .catch((error) => {
        console.error("Error deleting record:", error); // Log the error
        reject(error);
      });
  });
};

export const doUpsertRecord = (payload) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/admin/upsert${apiKey[payload.tab]}`, 
        payload.data,
        {
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


