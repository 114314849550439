import React, {PureComponent} from 'react';

// const categories = [
//   'Conveying_Documents'
//   ,'Leases'
//   // ,'Court_Documents'
//   // ,'Mortgages'
//   ,'Others'
// ];

export default class StyleControls extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      color: props.colors
    };
  }

  _renderLayerControl(name, color) {
    return (
      <div key={name} className="input">
        <input
          type="color"
          value={color}
          disabled
        />&nbsp;&nbsp;
        <label>{name.toUpperCase()}</label>
      </div>
    );
  }

  render() {
    return (
      <div className="control-panel" style={{ zIndex: "0"}}>
        {/* <label><b>INSTRUMENT CATEGORY</b></label>
        <hr /> */}
        {Object.keys(this.state.color).map(col => this._renderLayerControl(col, this.state.color[col]))}
        {/* {categories.map(name => this._renderLayerControl(name))} */}
      </div>
    );
  }
}
