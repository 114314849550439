import Modal from "../../components/common/modal";
import { useEffect, useState } from "react";
import { deleteRecord, upsertRecord } from "../../actions/dailyReport";
import { connect } from "react-redux";
import SelectView from "../../components/searchFilter/SelectViewNew";
import moment from "moment";
import Tag from "../../components/common/tagsInput";
import { payloadType } from "./tabDetails";

const DailyReportModal = ({
  theme,
  showModal,
  selectedRecord,
  closeModal,
  deleteRecord,
  dailyReportTabs,
  county,
  upsertRecord,
}) => {
  const [inputValues, setInputValues] = useState(selectedRecord.tabKey ? payloadType[selectedRecord.tabKey] : {});
  const [errorMessage, setErrorMessage] = useState(""); 
  
  useEffect(() => {
    if (selectedRecord?.id) {
      // Populate inputs when modal opens
      const record = dailyReportTabs
        .find((tab) => tab.key === selectedRecord.tabKey)
        ?.data.find((item) => item.Id === selectedRecord.id);
      const inputs = {};
      for (const key in record) {
        if(record[key] instanceof Date) {
          inputs[key] = moment(record[key]).tz('UTC').format("Y-MM-DD");
        } else {
          inputs[key] = record[key];
        }
      }
      setInputValues(inputs || {});
    }
    // eslint-disable-next-line
  }, [selectedRecord]);

  const tagChangeHandler = (e, key) => {
    setInputValues({
      ...inputValues,
      [key]: e.join(" | "),
    });
  };

  return (
    <>
      {/* Confirm Delete Modal */}
      <Modal
        showModal={showModal && selectedRecord.action === "delete"}
        header="Confirm Deletion"
        html="Are you sure you want to delete this record?"
        // margin={true}
        footerHtml={
          <div>
            <button
              className="btn btn-primary mr-3"
              onClick={() => {
                deleteRecord({
                  id: selectedRecord.id,
                  tabKey: selectedRecord.tabKey,
                });
                closeModal();
              }}
            >
              Yes
            </button>
            <button className="btn btn-info" onClick={closeModal}>
              Cancel
            </button>
          </div>
        }
        onHide={closeModal}
        theme={theme}
      />

      {/* Edit Record Modal */}
      <Modal
        margin={true}
        showModal={showModal && ["add", "edit"].includes(selectedRecord.action)}
        header={selectedRecord.header}
        additionalClass="daily-report-edit-modal"
        html={
          <div>
            {dailyReportTabs
              .find((tab) => tab.key === selectedRecord.tabKey)
              ?.cols.map((col, index) => {
                let mainElement = null;
                switch (col.__metadata__?.type) {
                  case "input":
                    mainElement = (
                      <input
                        type="text"
                        className="form-control"
                        value={inputValues[col.field] || ""}
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            [col.field]: e.target.value,
                          })
                        }
                      />
                    );
                    break;

                  case "tags":
                    mainElement = (
                      <Tag
                        tags={
                          inputValues[col.field]
                            ? inputValues[col.field].split(" | ")
                            : []
                        }
                        onChangeHandler={(e) => tagChangeHandler(e, col.field)}
                        // regexPattern={/^\d+[A-Z]-\d+[A-Z]-\d+ \([A-Z]{2}\)$/}
                      />
                    );
                    break;

                  case "date":
                    mainElement = (
                      <input
                        type="date"
                        className="form-control bg-transparent"
                        value={inputValues[col.field]}
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            [col.field]: e.target.value,
                          })
                        }
                      />
                    );
                    break;

                  case "textarea":
                    mainElement = (
                      <textarea
                        className="form-control bg-transparent"
                        value={inputValues[col.field]}
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            [col.field]: e.target.value,
                          })
                        }
                      />
                    );
                    break;

                  case "dropdown":
                    mainElement = (
                      <SelectView
                        className="filter"
                        options={county.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        values={
                          inputValues[col.field]
                            ? inputValues[col.field]
                                .split(" | ")
                                .map((item) => ({
                                  value: item,
                                  label: item,
                                }))
                            : []
                        }
                        multiple
                        searchable
                        placeholder="County"
                        onChange={(e) => {
                          setInputValues({
                            ...inputValues,
                            [col.field]: e.join(" | "),
                          });
                        }}
                        width={300}
                        theme={theme}
                      />
                    );
                    break;

                  default:
                    break;
                }

                return mainElement ? (
                  <div className="form-group" key={index}>
                    <label>{col.headerName}:</label>
                    {mainElement}
                  </div>
                ) : (
                  ""
                );
            })}
        </div>
        }
        footerHtml={
          <div className="d-flex justify-content-end align-items-center">
            <div className="mr-3">
              {errorMessage && <div className="error">{errorMessage}</div>}
            </div>
            <div>
              <button
                className="btn btn-primary mr-3 ml-2"
                onClick={() => {
                  if (!inputValues.trs) {
                    setErrorMessage("TRS cannot be empty.");
                    return; 
                  }
                  setErrorMessage("");
                  const payload = inputValues;
                  if (selectedRecord.action === "edit") payload["id"] = inputValues.Id;
                  upsertRecord(payload, selectedRecord.tabKey);
                  closeModal();
                }}
              >
                Save
              </button>
              <button className="btn btn-info" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        }
        onHide={closeModal}
        theme={theme}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  county: state.advancedSearch.county,
});

const mapDispatchToProps = (dispatch) => ({
  deleteRecord: (payload) => dispatch(deleteRecord(payload)),
  upsertRecord: (payload, tab) => dispatch(upsertRecord(payload, tab)),
});

const DailyReportModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyReportModal);

export default DailyReportModalContainer;
