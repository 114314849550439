/**
 * App Redux Action Types
 */
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const RESET_APP_REQUEST = "RESET_APP_REQUEST";

export const GET_INSTRUMENTS_REQUEST = "GET_INSTRUMENTS_REQUEST";
export const GET_INSTRUMENTS_SUCCESS = "GET_INSTRUMENTS_SUCCESS";
export const GET_INSTRUMENTS_FAILURE = "GET_INSTRUMENTS_FAILURE";

export const GET_TRS_DOCUMENT_REQUEST = "GET_TRS_DOCUMENT_REQUEST";
export const GET_TRS_DOCUMENT_SUCCESS = "GET_TRS_DOCUMENT_SUCCESS";
export const GET_TRS_DOCUMENT_FAILURE = "GET_TRS_DOCUMENT_FAILURE";

export const GET_INST_DOCUMENT_REQUEST = "GET_INST_DOCUMENT_REQUEST";
export const GET_INST_DOCUMENT_SUCCESS = "GET_INST_DOCUMENT_SUCCESS";
export const GET_INST_DOCUMENT_FAILURE = "GET_INST_DOCUMENT_FAILURE";

export const RESET_TRS = "RESET_TRS";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";

export const LOADING_START = "LOADING_START";
export const LOADING_FINISH = "LOADING_FINISH";

export const REMOVE_TAB = "REMOVE_TAB";

export const GET_RUNSHEET_REQUEST = "GET_RUNSHEET_REQUEST";
export const GET_RUNSHEET_SUCCESS = "GET_RUNSHEET_SUCCESS";
export const GET_RUNSHEET_FAILURE = "GET_RUNSHEET_FAILURE";

export const GET_SAVED_SHEETS_REQUEST = "GET_SAVED_SHEETS_REQUEST";
export const GET_SAVED_SHEETS_SUCCESS = "GET_SAVED_SHEETS_SUCCESS";
export const GET_SAVED_SHEETS_FAILURE = "GET_SAVED_SHEETS_FAILURE";

export const CREATE_RUNSHEET_REQUEST = "CREATE_RUNSHEET_REQUEST";
export const CREATE_RUNSHEET_SUCCESS = "CREATE_RUNSHEET_SUCCESS";
export const CREATE_RUNSHEET_FAILURE = "CREATE_RUNSHEET_FAILURE";

export const ADD_INSTRUMENT_RUNSHEET_REQUEST =
  "ADD_INSTRUMENT_RUNSHEET_REQUEST";
export const ADD_INSTRUMENT_RUNSHEET_SUCCESS =
  "ADD_INSTRUMENT_RUNSHEET_SUCCESS";
export const ADD_INSTRUMENT_RUNSHEET_FAILURE =
  "ADD_INSTRUMENT_RUNSHEET_FAILURE";

export const EDIT_RUNSHEET_INSTRUMENT = "EDIT_RUNSHEET_INSTRUMENT";
export const ADD_RUNSHEET_INSTRUMENT = "ADD_RUNSHEET_INSTRUMENT";
export const EDIT_RUNSHEET_SUCCESS = "EDIT_RUNSHEET_SUCCESS";
export const DELETE_RUNSHEET_REQUEST = "DELETE_RUNSHEET_REQUEST";
export const DELETE_RUNSHEET_SUCCESS = "DELETE_RUNSHEET_SUCCESS";
export const DELETE_RUNSHEET_FAILURE = "DELETE_RUNSHEET_FAILURE";

export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const UDPATE_USER_REQUEST = "UDPATE_USER_REQUEST";
export const UDPATE_USER_SUCCESS = "UDPATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_FAILURE = "UPDATE_USER_PASSWORD_FAILURE";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const SEND_INVITE_TO_USER_REQUEST = "SEND_INVITE_TO_USER_REQUEST";
export const SEND_INVITE_TO_USER_SUCCESS = "SEND_INVITE_TO_USER_SUCCESS";
export const SEND_INVITE_TO_USER_FAILURE = "SEND_INVITE_TO_USER_FAILURE";

export const HANDLE_PDF_EDIT = "HANDLE_PDF_EDIT";

export const SET_INSTRUMENT_ROW_HEIGHT_AND_MODE = "SET_INSTRUMENT_ROW_HEIGHT_AND_MODE";

export const OKCR_LOGIN_REQUEST = "OKCR_LOGIN_REQUEST";
export const OKCR_LOGIN_REQUEST_SUCCESS = "OKCR_LOGIN_REQUEST_SUCCESS";
export const OKCR_LOGIN_REQUEST_FAILURE = "OKCR_LOGIN_REQUEST_FAILURE";
export const OKCR_LISTING_REQUEST = "OKCR_LISTING_REQUEST";
export const OKCR_LISTING_SUCCESS = "OKCR_LISTING_SUCCESS";
export const OKCR_LISTING_FAILURE = "OKCR_LISTING_FAILURE";
export const OKCR_GET_PROGRESS_REQUEST = "OKCR_GET_PROGRESS_REQUEST";
export const OKCR_GET_PROGRESS_REQUEST_FAILURE = "OKCR_GET_PROGRESS_REQUEST_FAILURE";
export const OKCR_GET_PROGRESS_REQUEST_SUCCESS = "OKCR_GET_PROGRESS_REQUEST_SUCCESS";
export const OKCR_ACCOUNTS_REQUEST = "OKCR_ACCOUNTS_REQUEST";
export const OKCR_ACCOUNTS_REQUEST_SUCCESS = "OKCR_ACCOUNTS_REQUEST_SUCCESS";
export const OKCR_ACCOUNTS_REQUEST_FAILURE = "OKCR_ACCOUNTS_REQUEST_FAILURE";
export const OKCR_DELETE_USER_REQUEST = "OKCR_DELETE_USER_REQUEST";
export const OKCR_DELETE_USER_SUCCESS = "OKCR_DELETE_USER_SUCCESS";
export const OKCR_DELETE_USER_FAILURE = "OKCR_DELETE_USER_FAILURE";

export const CHANGE_THEME = "CHANGE_THEME";
export const SET_COLOR = "SET_COLOR";

export const CHANGE_MARKER_COLOR = "CHANGE_MARKER_COLOR"
export const ADVANCED_SEARCH_NEW_REQUEST = "ADVANCED_SEARCH_NEW_REQUEST";
export const ADVANCED_SEARCH_NEW_SUCCESS = "ADVANCED_SEARCH_NEW_SUCCESS";
export const ADVANCED_SEARCH_NEW_FAILURE = "ADVANCED_SEARCH_NEW_FAILURE";
export const ADVANCED_SEARCH_REQUEST = "ADVANCED_SEARCH_REQUEST";
export const ADVANCED_SEARCH_SUCCESS = "ADVANCED_SEARCH_SUCCESS";
export const ADVANCED_SEARCH_FAILURE = "ADVANCED_SEARCH_FAILURE";
export const INSTRUMENT_TYPE_LIST_REQUEST = "INSTRUMENT_TYPE_LIST_REQUEST";
export const INSTRUMENT_TYPE_LIST_SUCCESS = "INSTRUMENT_TYPE_LIST_SUCCESS";
export const INSTRUMENT_TYPE_LIST_FAILURE = "INSTRUMENT_TYPE_LIST_FAILURE";
export const SET_ADVANCED_ROW_HEIGHT_AND_MODE = "SET_ADVANCED_ROW_HEIGHT_AND_MODE";
export const GET_ADV_INST_DOCUMENT_REQUEST = "GET_ADV_INST_DOCUMENT_REQUEST";
export const GET_ADV_INST_DOCUMENT_SUCCESS = "GET_ADV_INST_DOCUMENT_SUCCESS";
export const GET_ADV_INST_DOCUMENT_FAILURE = "GET_ADV_INST_DOCUMENT_FAILURE";
export const DOWNLOAD_ADV_INST_DOCUMENT_REQUEST = "DOWNLOAD_ADV_INST_DOCUMENT_REQUEST";
export const DOWNLOAD_ADV_INST_DOCUMENT_SUCCESS = "DOWNLOAD_ADV_INST_DOCUMENT_SUCCESS";
export const DOWNLOAD_ADV_INST_DOCUMENT_FAILURE = "DOWNLOAD_ADV_INST_DOCUMENT_FAILURE";
export const REMOVE_ADV_TAB = "REMOVE_ADV_TAB";
export const SET_TRSM_REQUEST = "SET_TRSM_REQUEST";
export const SET_TAGS = "SET_TAGS";
export const REMOVE_TAG = "REMOVE_TAG";
export const RESET_TAGS = "RESET_TAGS";
export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";
export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA";

export const DOWNLOAD_INST_DOCUMENT_REQUEST = "DOWNLOAD_INST_DOCUMENT_REQUEST";
export const DOWNLOAD_INST_DOCUMENT_SUCCESS = "DOWNLOAD_INST_DOCUMENT_SUCCESS";
export const DOWNLOAD_INST_DOCUMENT_FAILURE = "DOWNLOAD_INST_DOCUMENT_FAILURE";

export const GET_PDF_COMMENTS_REQUEST = "GET_PDF_COMMENTS_REQUEST";
export const GET_PDF_COMMENTS_SUCCESS = "GET_PDF_COMMENTS_SUCCESS";
export const GET_PDF_COMMENTS_FAILURE = "GET_PDF_COMMENTS_FAILURE";

export const ADD_PDF_COMMENTS_REQUEST = "ADD_PDF_COMMENTS_REQUEST";
export const ADD_PDF_COMMENTS_SUCCESS = "ADD_PDF_COMMENTS_SUCCESS";
export const ADD_PDF_COMMENTS_FAILURE = "ADD_PDF_COMMENTS_FAILURE";

export const FETCH_PREVIOUS_FILTERS_REQUEST = "FETCH_PREVIOUS_FILTERS_REQUEST";
export const FETCH_PREVIOUS_FILTERS_SUCCESS = "FETCH_PREVIOUS_FILTERS_SUCCESS";
export const FETCH_PREVIOUS_FILTERS_FAILURE = "FETCH_PREVIOUS_FILTERS_FAILURE";
export const SET_PREVIOUS_VALUE_IN_FILTER = "SET_PREVIOUS_VALUE_IN_FILTER";
export const ADD_FILTER_IN_PREV_FILTERS = "ADD_FILTER_IN_PREV_FILTERS";

export const FETCH_USER_LOG_REQUEST = "FETCH_USER_LOG_REQUEST";
export const FETCH_USER_LOG_SUCCESS = "FETCH_USER_LOG_SUCCESS";
export const FETCH_USER_LOG_FAILURE = "FETCH_USER_LOG_FAILURE";
export const FETCH_USERS_LIST_REQUEST = "FETCH_USERS_LIST_REQUEST";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_FAILURE = "FETCH_USERS_LIST_FAILURE";

export const REPORT_ERROR_PDF_REQUEST = "REPORT_ERROR_PDF_REQUEST";
export const REPORT_ERROR_PDF_SUCCESS = "REPORT_ERROR_PDF_SUCCESS";
export const REPORT_ERROR_PDF_FAILURE = "REPORT_ERROR_PDF_FAILURE";

export const FETCH_ERROR_LOG_REQUEST = "FETCH_ERROR_LOG_REQUEST";
export const FETCH_ERROR_LOG_SUCCESS = "FETCH_ERROR_LOG_SUCCESS";
export const FETCH_ERROR_LOG_FAILURE = "FETCH_ERROR_LOG_FAILURE";

export const SET_FIRST_LOAD_VALUE = "SET_FIRST_LOAD_VALUE";

export const GET_OKCR_USERS_LIST_REQUEST = "GET_OKCR_USERS_LIST_REQUEST";
export const GET_OKCR_USERS_LIST_SUCCESS = "GET_OKCR_USERS_LIST_SUCCESS";
export const GET_OKCR_USERS_LIST_FAILURE = "GET_OKCR_USERS_LIST_FAILURE";

export const UPDATE_ERROR_DETAILS_REQUEST = "UPDATE_ERROR_DETAILS_REQUEST";
export const UPDATE_ERROR_DETAILS_SUCCESS = "UPDATE_ERROR_DETAILS_SUCCESS";
export const UPDATE_ERROR_DETAILS_FAILURE = "UPDATE_ERROR_DETAILS_FAILURE";

export const GET_ERROR_REPORT_DATA_REQUEST = "GET_ERROR_REPORT_DATA_REQUEST";
export const GET_ERROR_REPORT_DATA_SUCCESS = "GET_ERROR_REPORT_DATA_SUCCESS";
export const GET_ERROR_REPORT_DATA_FAILURE = "GET_ERROR_REPORT_DATA_FAILURE";

export const FETCH_DAILY_REPORT_REQUEST = "FETCH_DAILY_REPORT_REQUEST";
export const FETCH_DAILY_REPORT_SUCCESS = "FETCH_DAILY_REPORT_SUCCESS";
export const FETCH_DAILY_REPORT_FAILURE = "FETCH_DAILY_REPORT_FAILURE";

export const FETCH_DAILY_REPORT_DOC_REQUEST = "FETCH_DAILY_REPORT_DOC_REQUEST";
export const FETCH_DAILY_REPORT_DOC_SUCCESS = "FETCH_DAILY_REPORT_DOC_SUCCESS";
export const FETCH_DAILY_REPORT_DOC_FAILURE = "FETCH_DAILY_REPORT_DOC_FAILURE";

export const SET_REPORT_ROW_HEIGHT_AND_MODE = "SET_REPORT_ROW_HEIGHT_AND_MODE";

export const TRIGGER_LOCAL_GRID_FILTER = "TRIGGER_LOCAL_GRID_FILTER";

export const UPDATE_MY_PASSWORD_REQUEST = "UPDATE_MY_PASSWORD_REQUEST";
export const UPDATE_MY_PASSWORD_FAILURE = "UPDATE_MY_PASSWORD_FAILURE";
export const UPDATE_MY_PASSWORD_SUCCESS = "UPDATE_MY_PASSWORD_SUCCESS";

export const FETCH_DATA_LOG_REQUEST = "FETCH_DATA_LOG_REQUEST";
export const FETCH_DATA_LOG_SUCCESS = "FETCH_DATA_LOG_SUCCESS";
export const FETCH_DATA_LOG_FAILURE = "FETCH_DATA_LOG_FAILURE";

export const FETCH_DATA_ERROR_REQUEST = "FETCH_DATA_ERROR_REQUEST";
export const FETCH_DATA_ERROR_SUCCESS = "FETCH_DATA_ERROR_SUCCESS";
export const FETCH_DATA_ERROR_FAILURE = "FETCH_DATA_ERROR_FAILURE";

export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILURE = "UPDATE_USER_INFO_FAILURE";

export const GET_REGULATORY_APPS_ORDERS_DATA_REQUEST = "GET_REGULATORY_APPS_ORDERS_DATA_REQUEST";
export const GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS = "GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS";
export const GET_REGULATORY_APPS_ORDERS_DATA_FAILURE = "GET_REGULATORY_APPS_ORDERS_DATA_FAILURE";
export const GET_REGULATORY_APPS_ORDERS_DATA_REQUEST_NEW = "GET_REGULATORY_APPS_ORDERS_DATA_REQUEST_NEW";

export const FETCH_DAILY_REPORT_REQUEST_PARAMS = "FETCH_DAILY_REPORT_REQUEST_PARAMS";
export const DELETE_RECORD_REQUEST = "DELETE_RECORD_REQUEST";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILURE = "DELETE_RECORD_FAILURE";

export const UPSERT_RECORD_REQUEST = "UPSERT_RECORD_REQUEST";
export const UPSERT_RECORD_SUCCESS = "UPSERT_RECORD_SUCCESS";
export const UPSERT_RECORD_FAILURE = "UPSERT_RECORD_FAILURE";